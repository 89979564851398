import React from 'react'

const HomeImg = () => {
  return (
    <div className="blog-section padding-tb section-bg">
    <div className="container">

        <div className="section-wrapper">
            <div className="row  justify-content-center ">
        
                    <div className="col-lg-6" >
                       
                                    <img src="assets/images/homeimg2.jpg" style={{ width:"100%"}} />
                   
                    </div>
                    <div className="col-lg-6" >
                        
                                    <img src="assets/images/homeimg1.jpeg" style={{ width:"100%"}} />
                          
                    </div>
            
            </div>
           
        </div>
    </div>
</div>
  )
}

export default HomeImg