


const subTitle = "जय भारत जय संविधान ";
const title = <h2 className="title"><span className="d-lg-block">संविधान सन्मान परीक्षा २०२४</span></h2>;
const desc = "मानव लोक विकास शिक्षण व सेवाभावी मंडळ आयोजित";
const desc2 = "संविधान जनजागृती अभियान";


const catagoryList = [

]


const shapeList = [
    {
        name: '16M Students Happy',
        link: '#',
        className: 'ccl-shape shape-1',
    },
    {
        name: '130K+ Total Courses',
        link: '#',
        className: 'ccl-shape shape-2',
    },
    {
        name: '89% Successful Students',
        link: '#',
        className: 'ccl-shape shape-3',
    },
    {
        name: '23M+ Learners',
        link: '#',
        className: 'ccl-shape shape-4',
    },
    {
        name: '36+ Languages',
        link: '#',
        className: 'ccl-shape shape-5',
    },
]

const Banner = () => {
    return (
        <section className="banner-section">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-xxl-5 col-xl-6 col-lg-10" style={{paddingTop:'0'}}>
                            <div className="banner-content">
                                <h6 className="subtitle text-uppercase fw-medium" style={{letterSpacing: "0px"}}>{subTitle}</h6>
                                <p className="desc-2">{desc}</p>
                                <h5 className="desc">{desc2}</h5>
                                {title}
                                <form action="/">
                                    <div className="banner-icon">
                                        <i className="icofont-search"></i>
                                    </div>
                                    <input type="text" placeholder="येथे टाईप करा!" />
                                    <button type="submit">शोधा</button>
                                </form>
                                <div className="banner-catagory d-flex flex-wrap">
                                
                                    <ul className="lab-ul d-flex flex-wrap">
                                        {catagoryList.map((val, i) => (
                                            <li key={i}><a href={val.link}>{val.name}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-6">
                            <div className="banner-thumb d-flex justify-content-center">
                                <img src="assets/images/banner/01.png" alt="img" className="banner-img"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="all-shapes"></div>
            <div className="cbs-content-list d-none">
                <ul className="lab-ul">
                    {shapeList.map((val, i) => (
                        <li className={val.className} key={i}><a href={val.link}>{val.name}</a></li>
                    ))}
                </ul>
            </div>
        </section>
    );
}
 
export default Banner;