import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";



const CourseSingle = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>संविधान आहे महान, सर्वांना हक्‍क समान दर्जाची, संधीची समानता, हीच संविधानाची महानता</h3>
                                            
                                            <h4 style={{paddingTop: "5px"}}>परीक्षेचे नियम</h4>
                                            <ul className="lab-ul" style={{fontSize:"20px"}}>
                                                <li><i className="icofont-tick-mark"></i>परीक्षा मराठी व इंग्रजी दोन्ही पैकी एका भाषेत देता येईल.</li>
                                                <li><i className="icofont-tick-mark"></i>अभ्यास करण्यासाठी भारतीय संविधानाची मराठी, इंग्रजी पिडीएफ प्रत वेबसाईडवरून डाऊनलोड करून
                                                    घ्यावी लागेल.</li>
                                                <li><i className="icofont-tick-mark"></i>ऑनलाईन परीक्षा २६ जानेवारी २० २४ पासुन घेण्यात येईल. परीक्षेचे वेळापत्रक www.manavlokvikas.com या संकेतस्थळावर प्रदर्शित
                                                    करण्यात येईल.</li>
                                                <li><i className="icofont-tick-mark"></i> परिक्षार्थींनी भरलेली माहिती चुकीची आढळल्यास सदर परिक्षार्थीस बक्षीसास अपात्र केले जाईल.</li>
                                                <li><i className="icofont-tick-mark"></i>एक व्यक्ती किमान दोन गटात खुला व इतर पात्र
                                                    गटात निर्धारित केलेली परीक्षा शुल्क भरून नोंदणी करू शकतो.</li>
                                                <li><i className="icofont-tick-mark"></i>परिक्षा ऑनलाईन पव्हतीने होईल</li>
                                                <li><i className="icofont-tick-mark"></i> आवश्यकते नुसार ३, ४ चाळणी परिक्षा होतील</li>
                                                <li><i className="icofont-tick-mark"></i> एका दिवसात
                                                    ५ गटाची परिक्षा घेण्यात येईल</li>
                                                <li><i className="icofont-tick-mark"></i>  (Negative Marking) गुण कपात करण्यात येतील</li>
                                                <li><i className="icofont-tick-mark"></i> परिक्षेचा निकालं परिक्षा झाल्यावर २ दिवसांनी संकेतस्थावर प्रसारित
                                                    करण्यात येईल.</li>
                                                <li><i className="icofont-tick-mark"></i> सर्व सहभागी परिक्षार्थीना सह भाग प्रमाणपत्र डाऊनलोड करून घ्यावे लागेल.</li>
                                                <li><i className="icofont-tick-mark"></i>  परिक्षार्थी प्रथम परिक्षेस अनुपस्थिती असल्यास पुढील परिक्षा देण्यास
                                                    परिक्षार्थी पात्र नसेल.</li>
                                                <li><i className="icofont-tick-mark"></i>  परिक्षा ऑबजेक्टीव पध्दतीची असेल तर परिक्षाही १ ० ० प्रश्‍नांची व प्रत्येक प्रश्‍नांसाठी १ गुण व वेळ ५० मिनीटांचा असेल.</li>
                                                <li><i className="icofont-tick-mark"></i>  परिक्षार्थीनी
                                                    परिक्षेच्या ३ ० मिनीटे अगोदर ऑनलाईन उपस्थित असणे बंधनकारक असेल.</li>
                                                <li><i className="icofont-tick-mark"></i>   गरज वाटल्यास अंतिम परिक्षा ऑफ लाईन पध्दतीने सिसिटीव्हीच्या निगरानीत घेण्यात
                                                    येईल.</li>
                                                <li><i className="icofont-tick-mark"></i>    परिक्षार्थीना अंतिम ऑफ लाईन परिक्षेसाठी औरंगाबाद येथे उपस्थित राहावे लागेल. इथे - (स्वखर्चाने उपस्थित रहावे लागेल ) असे लिहा</li>
                                                <li><i className="icofont-tick-mark"></i>    वेळेनुसार नियम व अटीमध्ये बदल करण्याचा अधिकार संयोजक मंडळास असेल.</li>
                                                <li><i className="icofont-tick-mark"></i>   परिक्षेचे वेळापत्रक वेळोवेळी वेबसाईटवर प्रसारित केले जाईल.</li>
                                                <li><i className="icofont-tick-mark"></i>  परिक्षा व परिक्षेत विचारले जाणारे प्रश्‍न हे भारतीय संविधानाच्या आधारे असेल.</li>
                                                <li><i className="icofont-tick-mark"></i>    परिक्षाही गटानुसार घेण्यात येईल प्रश्‍नपत्रिका ही त्या-त्या गटाच्या बौध्दिक क्षमतेनुसार
                                                    असेल.</li>
                                                <li><i className="icofont-tick-mark"></i>   प्रत्येक गटातील प्रत्येक परिक्षेचे प्रश्‍न वेगवेगळे असतील.</li>
                                                <li><i className="icofont-tick-mark"></i>  सविस्तर माहितीसाठी वेबसाईटचे अवलोकन करा.</li>
                                                <li><i className="icofont-tick-mark"></i>  बक्षिस पात्र परिक्षार्थींना/ संयोजकास
                                                    बक्षिस वितरण समारंभ ठिकाणी औरंगाबाद ठिकाणी स्वखर्चाने उपस्थित रहावे लागेल</li>
                                                <li><i className="icofont-tick-mark"></i>सहभागी सर्व परिक्षार्थींना संविधान उद्देशिकेची फ्रेम व प्रमाणपत्र देण्यात येईल.</li>
                                                <li><i className="icofont-tick-mark"></i>परीक्षेचा निकाल परीक्षा झाल्यानंतर इथे - (परिक्षा झाल्यावर लगेच दिसेल ' ) असे लिहा</li>


                                            </ul>
                                        </div>
                                    </div>
                                </div>

 
                                <Author />
                                {/* <Comment /> */}
                                {/* <Respond /> */}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                {/* <CourseSideDetail />
                                <CourseSideCetagory /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default CourseSingle;