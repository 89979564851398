
import { Link } from "react-router-dom";
import Img from "../../assets/images/category/logoo.jpg"


const btnText = "आणखी वाचा";


const categoryList = [
    {
        imgUrl: 'assets/images/category/icon/01.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Computer Science',
        count: '24 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/02.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Civil Engineering',
        count: '04 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/03.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Business Analysis',
        count: '27 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/04.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Data Science Analytics',
        count: '28 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/05.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Learning Management',
        count: '78 Course',
    },
    {
        imgUrl: 'assets/images/category/icon/06.jpg',
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Computer Engineering',
        count: '38 Course',
    },
]


const Category = () => {
    return (
        <div className="category-section mt-3">
            <div className="container">
                <div className="section-header text-center">
                    {/* <span className="subtitle">{subTitle}</span> */}
                    {/* <h2 className="title">{title}</h2> */}
                    <h2 className="title">मानव लोक विकास शिक्षण व सेवाभावी मंडळ </h2>
                </div>
                <div className="section-wrapper">
                    {/* <div className="row g-2 justify-content-center row-cols-xl-6 row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                        </div>
                                        <div className="category-content">
                                            <Link to="/course"><h6>{val.title}</h6></Link>
                                            <span>{val.count}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        ))}
                    </div> */}
                    <div className="row g-2 justify-content-evenly align-items-center">
                        <div className="col-lg-4 col-md-8 d-flex justify-content-center">
                            <img src={Img} className="category-image" />
                        </div>
                        <div className="col-lg-6 col-md-8 about-us-description">
                            महाराष्ट्र राज्य संविधान जन- जागृती अभियान व्दारे आपणास कळवण्यास अत्याधिक आनंद होत आहे कि आपण सर्व २०२४ मध्ये प्रजासत्ताक दिनाचा अमृत महोत्सव साजरा करीत आहोत. त्या निमीत्ताने प्रत्येक भारतीय नागरिकांना आपले हक्क, कर्तव्य व अधिकार याची
                            माहिती व्हावी तसेच शासकीय, प्रशासकीय यंत्रणेचे ज्ञान व्हावे व सर्व भारतीय नागरिक सजक जागृत होऊन संविधानाप्रति आदर ठेवून जगामध्ये देशाची प्रतिमा सर्वोच्यस्थानी पोहचवण्यास सहकार्य करतील अशी आशा ठेवून संविधान जन जागृती अभियान राबवत आहोत. या राष्ट्रीय कार्यास, अभियानात आपण आपला अमूल्य सहभाग नोंदवून एक जागतिक विक्रम करण्यास सहभागी होण्याचे आव्हाण आपणास करत आहोत. संविधान जनजागृती अभियान हे मानव लोकविकास शिक्षण व सेवाभावी मंडळ अंतर्गत मा. धर्मदाय कार्यालय, बीड यांच्याकडे संस्था नोंदणी अधिनियम १८६० नुसार नोंदणी क्र. महा./ ७५ ३/० ५ दिनांक २२/०८/२००५ व मुंबई सार्वजनिक विश्वस्त व्यवस्था अधिनियम १९९५० अन्वये एफ- ११६३३ नोदनीकृत आहे . तसेच निती आयोग, आयकर विभाग 12 A, 80 G अंतर्गत नोंदणीकृत असून या संस्थेच्या वतीने हे अभियान राबविण्यात येत आहे.
                            <br></br>
                            <br></br>
                            <b>सदरील संस्थेच्या माध्यमातून २००५ पासून विविध बेरोजगार मार्गदर्शन शिबीर, महिला मेळावे, व्यवसाय मार्गदर्शन, गरजुंना मदत तसेच कोविड या महामारीच्याकाळात अविरतपणे जास्तीत-जास्त गरजुंना अन्न, धान्य, औषधी व इतर सेवा केल्याबद्दल संस्थेचा 'हाय रेंज बुक ऑफ वर्ल्ड रेकॉर्ड' सन्मान देऊन गौरविण्यात आलेले आहे.</b>
                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <Link to="/about" className="lab-btn"><span>{btnText}</span></Link>
                        <a href="/assets/pdf/Poster.pdf" target="_blank" type="button" className="lab-btn ms-2"><span>संविधान सन्मान परीक्षा माहिती पुस्तिका</span></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Category;