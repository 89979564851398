


const subTitle = "मानव लोक विकास शिक्षण व सेवाभावी मंडळ";
const title = "संविधान आहे महान, सर्वांना हक्‍क समान दर्जाची, संधीची समानता हीच संविधानाची महानता ";
const subHeading = "परीक्षेचे नियम";
const desc1 = "परीक्षा मराठी व इंग्रजी दोन्ही पैकी एका भाषेत देता येईल.";
const desc2 = " अभ्यास करण्यासाठी भारतीय संविधानाची मराठी, इंग्रजी पिडीएफ प्रत वेबसाईडवरून डाऊनलोड करून घ्यावी लागेल.";
const desc3 = "ऑनलाईन परीक्षा २६ जानेवारी २० २४ पासुन घेण्यात येईल. परीक्षेचे वेळापत्रक www.manavlokvikas.com या संकेतस्थळावर प्रदर्शित करण्यात येईल.";
const desc4 = "परिक्षार्थींनी भरलेली माहिती चुकीची आढळल्यास सदर परिक्षार्थीस बक्षीसास अपात्र केले जाईल.";
const desc5 = "एक व्यक्ती किमान दोन गटात खुला व इतर पात्र गटात निर्धारित केलेली परीक्षा शुल्क भरून नोंदणी करू शकतो.";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const About = () => {
    return (
        <div className="about-section">
            <div className="container">
                <div className="row pt-5 justify-content-evenly row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
                    <div className="col-lg-6 pt-5">
                        <div className="about-right">
                            <div className="section-header">
                                <span className="subtitle" style={{letterSpacing: "0px"}}>{subTitle}</span>
                                <h2 className="title">{title}</h2>
                                <br></br>

                                <h4 className="title">{subHeading}</h4>
                                <br></br>
                                <p>- {desc1}</p><br></br>
                                <p>- {desc2}</p><br></br>
                                <p>- {desc3}</p><br></br>
                                <p>- {desc4}</p><br></br>
                                <p>- {desc5}</p><br></br>
                            </div>
                            {/* <div className="section-wrapper">
                                <ul className="lab-ul">
                                    {aboutList.map((val, i) => (
                                        <li key={i}>
                                            <div className="sr-left">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="sr-right">
                                                <h5>{val.title}</h5>
                                                <p>{val.desc}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center">
                        <div className="about-left">
                            <div className="about-thumb">
                                <img src="assets/images/about/home-about-img.png" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;