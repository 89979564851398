import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/images/logo/logos.png";

const phoneNumber = "+91 9860095862";
const address =
  "प्लॉट नं.१७ , गट नं. ८८, गणेश नगर, सातारा परिसर, छत्रपती संभाजीनगर (औरंगाबाद) - ४३१००१";



const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`}>
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <i className="icofont-ui-call"></i> <span>{phoneNumber}</span>
              </li>
              <li>
                <i className="icofont-location-pin"></i> {address}
              </li>
            </ul>
            {/* <ul className="lab-ul social-icons d-flex align-items-center">
                            <li><p>Find us on : </p></li>
                            {socialList.map((val, i) => (
                                <li key={i}><a href={val.siteLink}><i className={val.iconName}></i></a></li>
                            ))}
                        </ul> */}
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container-xl">
          <div className="header-wrapper d-flex jusify-content-evenly">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                  <li>
                    <NavLink to="/">होमपृष्ठ</NavLink>
                  </li>
    
                  <li>
                    <NavLink to="/course-single">संविधान परीक्षा</NavLink>
                  </li>
                  <li  className="menu-item-has-children">
                  <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">अभियान &nbsp;</a>
                   
                    <ul  className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/blog">लेख</NavLink>
                      </li>
                      <li><NavLink to="/abhiyan-gallery">फोटो</NavLink></li>
                      {/* <li><NavLink to="/abhiyan-video">व्हिडिओ</NavLink></li> */}
                      <li><NavLink to="/pramukh-margdarshak">मार्गदर्शक</NavLink></li>
                      <li><NavLink to="/mukhai">मुख्य कायदेविषयक सल्लागार समिती</NavLink></li>
                      <li><NavLink to="/rajya-simti">राज्य समन्वय समिती</NavLink></li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                  <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">संयोजक  &nbsp;</a>
                    
                    <ul  className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/instructor">राज्य संयोजक</NavLink>
                      </li>
                      <li><NavLink to="/jilha-sanyojak">जिल्हा संयोजक</NavLink></li>
                      <li><NavLink to="/taluka-sanyojak">तालुका संयोजक</NavLink></li>
                      <li><NavLink to="/gat-sanyojak">गट- विभाग संयोजक</NavLink></li>
                      <li><NavLink to="/sansta-sanyojak">संस्था / संघटना / मंडळ समन्वयक</NavLink></li>
                      <li><NavLink to="/shala-sanyojak">शाळा / क्लासेस / अकॅडमी / महाविघालय / विद्यापिठ</NavLink></li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                  <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">गॅलरी &nbsp;</a>
   
                    <ul  className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/gallery">फोटो गॅलरी</NavLink>
                      </li>
                      <li><NavLink to="/video-gallery">व्हिडीओ गॅलरी</NavLink></li>
                      {/* <li>
                        <a>न्यूज गॅलरी</a>
                      </li> */}
                    </ul>
                  </li>
                  <li>
                    <NavLink to="/about">आमच्या बद्दल</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">संपर्क</NavLink>
                  </li>
                  <li>
                    <NavLink to="/madad">मदत </NavLink>
                  </li>
                  <li>
                    <NavLink to="/donate">देणगी </NavLink>
                  </li>
                </ul>
              </div>

              <Link to="/login" className="login">
                <i className="icofont-user"></i> <span>लॉगइन</span>{" "}
              </Link>
              <Link to="/signup" className="signup">
                <i className="icofont-users"></i> <span>नोंदणी</span>{" "}
              </Link>

              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
