import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";
import { Carousel } from "react-bootstrap";

const subTitle = "Our Achievements";

const eventOneImages = [
    {
      imgUrl: "assets/images/ourCertificates/1.jpeg",
    },
    // {
    //   imgUrl: "assets/images/ourCertificates/2.jpeg",
  
    // },
    {
      imgUrl: "assets/images/ourCertificates/3.jpeg",
  
    },
    {
      imgUrl: "assets/images/ourCertificates/4.jpeg",
  
    },
    {
      imgUrl: "assets/images/ourCertificates/5.jpeg",
  
    },
    {
      imgUrl: "assets/images/ourCertificates/6.jpeg",
  
    },
    {
      imgUrl: "assets/images/ourCertificates/7.jpeg",
  
    },
    {
      imgUrl: "assets/images/ourCertificates/8.jpeg",
  
    },
    {
      imgUrl: "assets/images/ourCertificates/9.jpeg",
  
    },
    
    {
      imgUrl: "assets/images/ourCertificates/10.jpeg",
  
    },
    
    {
      imgUrl: "assets/images/ourCertificates/11.jpeg",
  
    },
    
  ]
const courseList = [
  {
    imgUrl: "assets/images/gallery/1682082739337.jpg",
  },
  {
    imgUrl: "assets/images/gallery/1682082739370.jpg",
  },
  {
    imgUrl: "assets/images/gallery/1682082739542.jpg",
  },
  {
    imgUrl: "assets/images/gallery/1682082739567.jpg",
  },
  {
    imgUrl: "assets/images/gallery/FB_IMG_1552276907863.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20141213_184330.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20141213_194347.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20141213_195641.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190511_111915.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190511_111942.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190614_123618.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190614_124244.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190614_131657.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190614_133059.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190614_134219.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20190817_162551.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20200308_174412.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20200308_174440.jpg",
  },
  {
    imgUrl: "assets/images/gallery/IMG_20200308_175610.jpg",
  },
];

const OurCertificates = ({ limit }) => {
  return (
    <div className="course-section section-bg">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          {/* <h2 className="title">{title}</h2> */}
        </div>
        <div className="section-wrapper">
          <div className="row justify-content-center">
            <div className=" col-12 col-md-8 col-sm-10">
              <Carousel controls={false} indicators={false} interval={3000}>
                {eventOneImages.map((item, i) => {
                    return (
                      <Carousel.Item className="certificate-div" >
                        <img
                          className="certificate-images"
                          src={`${item.imgUrl}`}
                          alt="gallery-image"
                        />
                      </Carousel.Item>
                    );
                })}
              </Carousel>
              {/* <h3 className=" event-titles">रकतदान शिबिर</h3> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCertificates;
