
import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';


import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import AboutPage from "./page/about";
import BlogPage from "./page/blog";
import BlogSingle from "./page/blog-single";
import CartPage from "./page/cart-page";
import ContactPage from "./page/contact";
import CoursePage from "./page/course";
import CourseSingle from "./page/course-single";
import ForgetPass from "./page/forgetpass";
import Home from "./page/home";
import InstructorPage from "./page/instructor";
import LoginPage from "./page/login";
import SearchNone from "./page/search-none";
import SearchPage from "./page/search-page";
import ShopPage from "./page/shop";
import ShopDetails from "./page/shop-single";
import SignupPage from "./page/signup";
import TeamPage from "./page/team";
import TeamSingle from "./page/team-single";
import PrivacyPage from "./page/privacy";
import Gallery from "./page/gallery";
import TalukaSanyojak from "./page/TalukaSanyojak";
import JilhaSanyojak from "./page/JilhaSanyojak";
import GatSanyojak from "./page/GatSanyojak";
import ItarSanyojak from "./page/ItarSanyojak";
import ShalaSanyojak from "./page/ShalaSanyojak";
import Madad from "./page/Madad";
import PramukhMargdarshak from "./page/PramukhMargdarshak";
import Mukhai from "./page/Mukhai";
import RajyaSimti from "./page/RajyaSimti";
import AbhiyanGallery from "./page/AbhiyanGallery";
import VerifyOtp from "./page/VerifyOtp";
import PaymentDetails from "./page/PaymentDetails";
import ProceedToPay from "./page/ProceedToPay";
import VideoGallery from "./page/VideoGallery";
import SabhasadVerifyOtp  from "./page/SabhasadVerifyOtp";
import SabhasadPaymentDetails from "./page/SabhasadPaymentDetails";
import Donation from "./page/Donation";
import StudentPaymentDetail from "./page/StudentPaymentDetail";
function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="course" element={<CoursePage />} />
				<Route path="course-single" element={<CourseSingle />} />
				<Route path="blog" element={<BlogPage />} />
				<Route path="blog-single" element={<BlogSingle />} />
				<Route path="about" element={<AboutPage />} />
				<Route path="privacy-policy" element={<PrivacyPage />} />
				<Route path="team" element={<TeamPage />} />
				<Route path="team-single" element={<TeamSingle />} />
				<Route path="gallery" element={<Gallery />} />
				<Route path="instructor" element={<InstructorPage />} />
				<Route path="taluka-sanyojak" element={<TalukaSanyojak />} />
				<Route path="jilha-sanyojak" element={<JilhaSanyojak />} />
				<Route path="sansta-sanyojak" element={<ItarSanyojak />} />
				<Route path="shala-sanyojak" element={<ShalaSanyojak />} />
				<Route path="gat-sanyojak" element={<GatSanyojak />} />
				<Route path="pramukh-margdarshak" element={<PramukhMargdarshak />} />
				<Route path="mukhai" element={<Mukhai />} />
				<Route path="abhiyan-gallery" element={<AbhiyanGallery />} />
				<Route path="rajya-simti" element={<RajyaSimti />} />
				<Route path="shop" element={<ShopPage />} />
				<Route path="shop-single" element={<ShopDetails />} />
				<Route path="cart-page" element={<CartPage />} />
				<Route path="search-page" element={<SearchPage />} />
				<Route path="search-none" element={<SearchNone />} />
				<Route path="contact" element={<ContactPage />} />
				<Route path="login" element={<LoginPage />} />
				<Route path="signup" element={<SignupPage />} />
				<Route path="madad" element={<Madad />} />
				<Route path="video-gallery" element={<VideoGallery />} />
				<Route path="forgetpass" element={<ForgetPass />} />
				<Route path="verify-mobile-otp/:uniquekey" element={<VerifyOtp />} />
				<Route path="sabhasad-verify-mobile-otp/:uniquekey" element={<SabhasadVerifyOtp />} />
				<Route path="payment-details/:paymentToken" element={<PaymentDetails />} />
				<Route path="sabhasad-payment-details/:paymentToken" element={<SabhasadPaymentDetails />} />
				<Route path="student-payment-details/:paymentToken" element={<StudentPaymentDetail />} />
				<Route path="payment-id" element={<ProceedToPay />} />
				<Route path="donate" element={<Donation />} />
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
