import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";

const subTitle =
  "संविधान आहे महान, सर्वांना हक्क समान ! दर्जाची, संधीची समानता हीच संविधानाची महानता";
const title = "अरविंद कांबळे";

const instructorList = [
  {
    imgUrl: "assets/images/instructor/sarikha-potbhare.jpg",
    imgAlt: "instructor",
    name: "सौ. सारिका पोटभरे ",
    nameSubline: "अध्यक्ष",
    degi: "",
    degi2: "",
    text: "शिक्षण एच एस सी डि एड",
  },
  {
    imgUrl: "assets/images/instructor/arvind-kambale.jpg",
    imgAlt: "instructor",
    name: "अरविंद कांबळे ",
    nameSubline: "उपाध्यक्ष",

    degi: "मानव लोक विकास शिक्षण व सेवाभावी मंडळ",
    degi2: "",
    text: "शिक्षण : बि ए / तंत्रशिक्षण शैक्षणिक -सामाजिक - आंदोलनामधे सहभाग, गायरान जमीन धारकांसाठी आंदोलने सामाजिक कार्यात सक्रिय !",
  },
  {
    imgUrl: "assets/images/instructor/jayprakas-takankar.jpg",
    imgAlt: "instructor",
    name: "जयप्रकाश टाकणखार ",
    nameSubline: "सचिव",
    degi: "मानव लोक विकास शिक्षण व सेवाभावी मंडळ",
    degi2: "सहशिक्षक : म.शि.प्र. मंडळ छत्रपती संभाजीनगर",
    text: "शिक्षण : एम ए बि एड सहशिक्षक - न्यू हायस्कूल धोंदलगांव येथे २००6 पासून कार्यरत असून . सामाजिक बांधिलकी म्हणून आजपर्यंत ४० + गरजूंना रक्तदान केलेले आहे तसेच सामाजिक परंपरेला नाकारत मृत्यूनंतर देखील आपला देह वैद्यकिय शिक्षण घेत असलेल्या विद्यार्थांना मानवी देहाचा अभ्यास प्रत्यक्ष करता यावा या उद्देशाने सपत्नीक मरणोत्तर देहदानाचा संकल्प देखील केलेला आहे ! कोरोना काळात अनेक गरजू, व रुग्नांना सेवा दिल्याबददल - अविरत मदत कार्य केल्या बददल संस्थेचा हासरेंज बुक ऑफ वर्ल्ड रेकॉर्ड ने सम्मान देखील करण्यात आलेला आहे",
    certificate: "assets/images/instructor/certificates/1.jpeg",
  },
  {
    imgUrl: "assets/images/instructor/sulakshna.jpg",
    imgAlt: "instructor",
    name: "सुलक्षणा टाकणखार ",
    nameSubline: "कोषाध्यक्ष",
    degi: "मानव लोक विकास शिक्षण व सेवाभावी मंडळ",
    degi2: "",
    text: "शिक्षण : एच एस सी / कृषि पद्‌विका <br></br> सामाजिक बांधिलकी म्हणून अनेक वेळा रक्तदान केलेले आहे तसेच सामाजिक परंपरेला नाकारत मृत्यूनंतर देखील आपला देह वैद्यकिय शिक्षण घेत असलेल्या विद्यार्थांना मानवी देहाचा अभ्यास प्रत्यक्ष करता यावा या उद्देशाने मरणोत्तर देहदानाचा संकल्प देखील केलेला आहे!",
    certificate: "assets/images/instructor/certificates/2.jpeg",
  },
];

const Instructor = () => {
  const [instructorModalOpen, setInstructorModalOpen] = useState(false);
  const [modalData, setModaldata] = useState({});

  function openModal(name, subline, info, image, text, certificate = null) {
    setModaldata({
      name,
      subline,
      info,
      image,
      text,
      certificate,
    });
    setInstructorModalOpen(true);
  }

  return (
    <>
      <div className="instructor-section padding-tb section-bg">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle" style={{ letterSpacing: "0px" }}>
              {subTitle}
            </span>
            {/* <h2 className="title">{title}</h2> */}
          </div>
          <div className="section-wrapper">
            <div className="row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
              {instructorList.map((val, i) => (
                <div className="col" key={i}>
                  <div className="instructor-item h-100">
                    <div className="instructor-inner">
                      <div className="instructor-thumb">
                        <img
                          src={`${val.imgUrl}`}
                          alt={`${val.imgAlt}`}
                          className="instructor-image"
                          onClick={() => {
                            openModal(
                              val.name,
                              val.nameSubline,
                              val.degi + val.degi2,
                              val.imgUrl,
                              val.text,
                              val.certificate || null
                            );
                          }}
                        />
                      </div>
                      <div className="instructor-content">
                        <Link to="/about">
                          <h4>{val.name}</h4>
                          <h4>{val.nameSubline}</h4>

                        </Link>
                        <p>{val.degi}</p>
                        <p>{val.degi2 && val.degi2}</p>
                        <Rating />
                      </div>
                    </div>
                    {/* <div className="instructor-footer">
                                        <ul className="lab-ul d-flex flex-wrap justify-content-between align-items-center">
                                            <li><i className="icofont-book-alt"></i> {val.courseCount}</li>
                                            <li><i className="icofont-users-alt-3"></i> {val.studentAnroll}</li>
                                        </ul>
                                    </div> */}
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="text-center footer-btn">
              <p>
                Want to help people learn, grow and achieve more in life?
                <Link to="/team">Become an instructor</Link>
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <Modal
        show={instructorModalOpen}
        onHide={() => setInstructorModalOpen(false)}
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="custom-modal-2"
      >
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
          }}
        >
          <div className="row w-100 justify-content-center align-items-center">
            <div className="col-8 col-sm-6 ">
              <div className="instructor-thumb">
                <img
                  src={modalData.image}
                  alt={modalData.name}
                  sx={{width: "100%", height: "auto"}}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 py-3">
              <p
                className="instructor-name-in-modal"
                style={{ letterSpacing: "0px" }}
              >
                {modalData.name} <br></br>
                {modalData.subline}
              </p>
              <p
                className="instructor-details-in-modal"
                style={{ letterSpacing: "0px" }}
              >
                {modalData.info}
              </p>
            </div>

            <hr className="mx-3 my-4 w-100"></hr>
            {modalData.certificate && (
              <div className="col-12">
                <div className="instructor-certificate-thumb py-3">
                  <img
                    src={modalData.certificate}
                    alt={modalData.name}
                    className="instructor-certificate"
                  />
                </div>
              </div>
            )}
            <div className="col-12 py-3">
              <p
                className="instructor-details-in-modal"
                style={{ letterSpacing: "0px" }}
                dangerouslySetInnerHTML={{__html: modalData.text}}
              >
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Instructor;
